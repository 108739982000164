












import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import Tab from "@/shared/classes/tab";
import Tabs from "@/shared/components/Tabs.vue";
import { AdminRoutes } from "@/shared/router/admin";
import baseHttp from "@/shared/http";
import { getNodeApiUrlByVersion } from "@/config";

@Component({
  components: { PageHeader, Tabs },
  methods: { __ }
})
export default class CourseDetailPage extends Vue {
  http = baseHttp
  name = ''

  created() {
    this.http.get(`${getNodeApiUrlByVersion()}/courses/${this.$route.params.uuid}`)
      .then((response: any) => this.name = response.data?.name)
  }

  tabs = [
    new Tab()
      .setTo(AdminRoutes.courseDetailPageOverview)
      .setTitle(__('admin.views.courses.detail-page.overview')),
    new Tab()
      .setTo(AdminRoutes.courseDetailPageTest)
      .setTitle(__('admin.views.courses.detail-page.test')),
    new Tab()
      .setTo(AdminRoutes.courseDetailPageMaterials)
      .setTitle(__('admin.views.courses.detail-page.materials'))
  ]

  goBack(): void {
    this.$router.push({ name: AdminRoutes.coursesIndex });
  }
}
